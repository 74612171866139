import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

import { BsFillPlusCircleFill } from "react-icons/bs";

function Admin(props) {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [usersSkip, setUsersSkip] = useState(0)
    const [allUsers, setAllUsers] = useState(false);

    const [query, setQuery] = useState("");

    useEffect(() => {
      load();
    }, []);

    const load = async () => {
      const newUsers = await fetchUsers(props.token.tokenString, props.setToken, usersSkip);
      if (newUsers.length < 100) {
        setAllUsers(true);
      }

      if (usersSkip == 0) {
        setUsers([...newUsers]);
      } else {
        setUsers([...users, ...newUsers]);
      }

      setUsersSkip(usersSkip + 100);
      setLoading(false);
    }

    const search = async (query) => {
      query = query.replaceAll("/", "").replaceAll(/\s/g, "");

      if (query == "") {
        setUsers([]);
        setLoading(true);
        load();
        return;
      }

      setLoading(true);
      const foundUsers = await fetchSearch(props.token.tokenString, query);
      setUsers(foundUsers);
      setUsersSkip(0);
      setAllUsers(false);
      setLoading(false);
    }

    return(
      <div className={"box"}>
          <h1>Nutzer</h1>
          <button onClick={(e) => document.location.href="/user/create"}><BsFillPlusCircleFill style={{fontSize: "17px", verticalAlign: "top"}}/> Neuen Nutzer anlegen</button><br/><br/>
          <input className="search" placeholder="Suche nach Nutzern..." onChange={(e) => {setQuery(e.target.value); search(e.target.value)}} />
          <br/><br/>
          <table>
          <th>E-Mail</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Organisation</th>
          <th>Rolle</th>
          <th>Tier</th>
          <th>Tokennutzung</th>
          {loading ? <tr><td colspan="6">Lade Nutzer...</td></tr> : ""}
          {users.map(item => (
            <tr className="clickable" onClick={(e) => document.location.href="/user/edit/" + item.id}>
              <td>{item.email}</td>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.organization}</td>
              <td>{item.roles?.length > 0 ? item.roles[0] : ""}</td>
              <td>{item.tier}</td>
              <td>{item.tokenUsage}</td>
            </tr>
          ))}
          </table>
          <br/><br/>
          {loading || allUsers || query.length > 0 ? "" : <button className="small" onClick={(e) => load()}>Weitere Nutzer laden</button>}
      </div>
    )
}

function fetchUsers(token, setToken, skip) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/all/' + skip + '/100', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchSearch(token, query) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/search/' + query, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}



export default Admin;
